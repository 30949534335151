import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'

const tableContent = () => {
  let content = [];

  for(let i=1; i<=15; i++) {
    if(i<10) {
      content.push(
        <tr>
          <td>{i}</td>
          <td>{'mpuser00' + i}</td>
          <td className="text-align-right">{'1,000'}</td>
          <td className="text-align-right negative">{'-806.24'}</td>
          <td>Active</td>
          <td>{'0'+i+'/06/2020 0'+i+':47:33 AM'}</td>
        </tr>
      )
    }
    else {
      content.push(
        <tr>
          <td>{i}</td>
          <td>{'mpuser0' + i}</td>
          <td className="text-align-right">{'9,000'}</td>
          <td className="text-align-right">{'0.00'}</td>
          <td>Active</td>
          <td>02/06/2020 02:47:33 AM</td>
        </tr>
      )
    }
  }

  return content;
}
function CreditBalance() {
  DashboardStore.setActivePage('17');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Credit</h2>
      <Table className="table" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={14}>
              <Row>
                <Col md={{ span: 2, offset: 10 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Active
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Credit</th>
            <th>Cash Balance</th>
            <th>Status</th>
            <th>Last Login Time</th>
          </tr>
        </thead>
        <tbody>
          {tableContent()}
        </tbody>
      </Table>
    </div>
  );
}

export default CreditBalance;
