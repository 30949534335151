import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Checkbox } from 'antd';

const tableContent = () => {
  let content = [];

  for(let i=1; i<=20; i++) {
    if(i<10) {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser00' + i}</td>
          <td>{(i%6 === 0) ? 'chgroup' : '--'}</td>
          <td><Checkbox checked={(i%3 === 0) ? true : false}/></td>
          <td>Open</td>
          <td>No</td>
          <td>Unlock</td>
          <td>{'tes'}</td>
          <td>{(i%4 === 0) ? 'test' : ''}</td>
          <td></td>
          <td></td>
          <td>{'0'+i+'/06/2020 0'+i+':47:33 AM'}</td>
        </tr>
      )
    }
    else {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser0' + i}</td>
          <td>--</td>
          <td><Checkbox /></td>
          <td>Open</td>
          <td>No</td>
          <td>Unlock</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>02/06/2020 02:47:33 AM</td>
        </tr>
      )
    }
  }

  return content;
}
function AccountList() {
  DashboardStore.setActivePage('11');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Account List</h2>
      <Table className="table" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={14}>
              <Row>
                <Col md={{ span: 2, offset: 10 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      All
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <th>#</th>
            <th><Checkbox /></th>
            <th>Username</th>
            <th>Login Name</th>
            <th>View Rebate Report</th>
            <th>Status</th>
            <th>Suspend</th>
            <th>Lock</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Mobile</th>
            <th>Last Login Time</th>
          </tr>
        </thead>
        <tbody>
          {tableContent()}
        </tbody>
      </Table>
    </div>
  );
}

export default AccountList;