import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Breadcrumb } from 'antd';

function getCommaSeparatedTwoDecimalsNumber(number) {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}
const tableContent = () => {
  let content = [];

  for(let i=1; i<=8; i++) {
    if(i%2===1) {
        content.push(
            <tr className="alt-blue">
                <th colSpan="4">IDR</th>
            </tr>
        )
      }
    content.push(
    <tr>
        <td>{(i > 4) ? i-4: i}</td>
        <td><span className="dotted-underline">{'mpuser00' + i}</span></td>
        <td>{(i < 5) ? 'IDR' : 'THB' }</td>
        <td>{getCommaSeparatedTwoDecimalsNumber(10264.65 + ( i * 32))}</td>
        <td>0.00</td>
        <td className="negative">{getCommaSeparatedTwoDecimalsNumber(-124.23 - ( i * 4))}</td>
        <td>0.00</td>
        <td>{(i%4===0) ? getCommaSeparatedTwoDecimalsNumber(720.76 - ( i * 4)) : '0.00'}</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
    </tr>
    )
  }

  return content;
}
function MatchResults() {
    DashboardStore.setActivePage('6');
    DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Match Results</h2>
      <div style={{"overflowX":"auto", "overflowY": "auto"}}>
        <Table className="table vertical-align-middle-table" bordered >
            <thead>
                <tr className="filter-bar">
                    <td colSpan={19}>
                        <Row className="filter-bar-row justify-flex-start">
                            <Col md={{ span: 2 }} className="right-border">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Today
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                            <Col md={{ span: 2 }} className="pr-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                            <Col className="px-1 align-self-center daterange-to">to</Col>
                            <Col md={{ span: 2 }} className="pl-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <th>Kickoff</th>
                    <th>Teams</th>
                    <th>First Half Score</th>
                    <th>Full Time Score</th>
                </tr>
            </thead>
            <tbody>
                <tr className="light-blue">
                    <th colSpan="4" className="TDLeague">International Friendly</th>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Republic of North Macedonia U18 -vs- Bosnia-Herzegovina U19</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">6:00:00</span> 
                    </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">0 : 0</td>
                    <td className="TAC W90">1 : 0</td>
                </tr>
                <tr className="light-blue">
                    <th colSpan="4" className="TDLeague">Club Friendly</th>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Long An FC -vs- Fico Tay Ninh</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">4:30:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">2 : 0</td>
                    <td className="TAC W90">3 : 1</td>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Malaga CF -vs- FC Krasnodar</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">6:00:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">1 : 0</td>
                    <td className="TAC W90">1 : 0</td>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Arsenal Tula (n) -vs- Ordabasy Shymkent</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">10:00:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">1 : 0</td>
                    <td className="TAC W90">2 : 0</td>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>IF Kolding (n) -vs- FC Rukh Vynnyky</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">10:00:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">0 : 0</td>
                    <td className="TAC W90">0 : 2</td>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Strommen IF (n) -vs- Kjelsas Fotball</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">11:15:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">1 : 1</td>
                    <td className="TAC W90">3 : 2</td>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Turris Oltul Turnu Magurele -vs- Progresul Spartac</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/20
                        <br /><span className="WSN">11:59:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">0 : 1</td>
                    <td className="TAC W90">1 : 2</td>
                </tr>
                <tr className="alt-blue">
                    <td colSpan="4" className="BTGrp"><span>Hinna Fotball -vs- FK Vidar</span></td>
                </tr>
                <tr className="CSS_CLASS">
                    <td className="TAC W90"> 02/21
                        <br /><span className="WSN">3:00:00</span> </td>
                    <td className="TAL">Main Event</td>
                    <td className="TAC W90">0 : 4</td>
                    <td className="TAC W90">1 : 5</td>
                </tr>
            </tbody>
        </Table>
        </div>
    </div>
  );
}

export default MatchResults;
