import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Table from 'react-bootstrap/Table';

const tableContent = () => {
  let content = [];

  for(let i=1; i<=10; i++) {
      content.push(
        <tr key={'row'+i}>
          <td>{(i<10) ? 'mpuser00' + i : 'mpuser0'+i}</td>
          <td>THB</td>
          <td>{i}</td>
          <td>{i*2}</td>
          <td>{i*2}</td>
          <td>{i*2}</td>
          <td className="text-align-right">{(i%3===0)? '33.33%' : '100.00%'}</td>
          <td className="text-align-right">{(i%3===0)? '300.00' : '658.00'}</td>
          <td className="text-align-right">{(i%3===0)? '300.00' : '658.00'}</td>
          <td className="text-align-right">{'1,000.00'}</td>
          <td className="text-align-right">{(i%3===0)? '1,002.00' : '976.00'}</td>
        </tr>
      )
  }

  return content;
}
function DashboardWinningPlayers() {
  return (
    <div className="dashboard">
      <Table className="table" bordered striped >
          <thead>
          <tr>
              <th rowSpan={2}>Username</th>
              <th rowSpan={2}>Currency</th>
              <th colSpan={4}>Bet Count</th>
              <th rowSpan={2}>Win %</th>
              <th rowSpan={2}>Avg. Bet</th>
              <th rowSpan={2}>Lowest Bet</th>
              <th rowSpan={2}>Highest Bet</th>
              <th rowSpan={2}>Win Amount</th>
          </tr>
          <tr>
              <th>Total #</th>
              <th>Win #</th>
              <th>Draw #</th>
              <th>Lose #</th>
          </tr>
          </thead>
          <tbody>
          {tableContent()}
          </tbody>
      </Table>
    </div>
  );
}

export default DashboardWinningPlayers;
