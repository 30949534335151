import React from 'react';
import { observer } from "mobx-react"
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from './Stores/DashboardStore';
import NavigationBar from './Components/NavigationBar';
import HeaderSection from './Components/HeaderSection';
import Main from './Routes/Main';
import { BrowserRouter } from 'react-router-dom'
import { Layout } from 'antd';
const { Content } = Layout;

const App = observer((props) => {
  return (
    <BrowserRouter>
        <Layout>
            <NavigationBar/>
            <Layout 
              className={(DashboardStore.ob.menuCollapsed) ? 'site-layout' : 'site-layout contentWidth'}
            >
                <HeaderSection/>
                <Content><Main /></Content>
            </Layout>
        </Layout>
    </BrowserRouter>
  );
});

export default App;
