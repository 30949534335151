import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Checkbox } from 'antd';

const tableContent = () => {
  let content = [];

  for(let i=1; i<=20; i++) {
    if(i<10) {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser00' + i}</td>
          <td>{'tes'}</td>
          <td>{'test'}</td>
          <td className="text-align-right">2</td>
          <td className="text-align-right">7,999</td>
          <td className="text-align-right">100,000</td>
          <td className="text-align-right">10,000</td>
          <td className="text-align-right">10,000</td>
          <td>Active</td>
        </tr>
      )
    }
    else {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser0' + i}</td>
          <td>{''}</td>
          <td>{''}</td>
          <td className="text-align-right">10</td>
          <td className="text-align-right">8,999</td>
          <td className="text-align-right">40,000</td>
          <td className="text-align-right">10,000</td>
          <td className="text-align-right">10,000</td>
          <td>Active</td>
        </tr>
      )
    }
  }

  return content;
}
function BettingLimit() {
  DashboardStore.setActivePage('13');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Betting Limit</h2>
      <Table className="table" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={14}>
              <Row>
                <Col md={{ span: 2, offset: 10 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Active
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <th>#</th>
            <th><Checkbox /></th>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Min Bet</th>
            <th>Max Bet</th>
            <th>Max Per Match</th>
            <th>Maximum Win</th>
            <th>Maximum Loss</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {tableContent()}
        </tbody>
      </Table>
    </div>
  );
}

export default BettingLimit;
