import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Breadcrumb, Button } from 'antd';

function BetList() {
    DashboardStore.setActivePage('3');
    DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
        <h2>Bet List</h2>
        <div className="breadcrumbs">
            <Row>
            <Col md={{ span: 8 }}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/shareholderreport">
                        <Icon className="icon" type="team" />
                        <span>All</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/shareholderreportSMA">
                        <Icon className="icon" type="user" />
                        <span>A</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/shareholderreportMA">
                        <Icon className="icon" type="user" />
                        <span>smauser001</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/shareholderreportAgt">
                        <Icon className="icon" type="user" />
                        <span>mauser001</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/betlist">
                        <Icon className="icon" type="user" />
                        <span>agtuser001</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col md={{ span: 4 }} className="text-align-right">
                <Button type="primary" icon="file-excel">Export to Excel</Button>
            </Col>
            </Row> 
        </div>
      <div style={{"overflowX":"auto", "overflowY": "auto"}}>
        <Table className="table vertical-align-middle-table" bordered striped >
            <thead>
                <tr>
                    <th rowSpan={2}>#</th>
                    <th rowSpan={2}>Information</th>
                    <th rowSpan={2} style={{'minWidth':'300px'}}>Selection</th>
                    <th rowSpan={2}>Odds</th>
                    <th rowSpan={2}>Stake</th>
                    <th rowSpan={2}>Status</th>
                    <th colSpan={4}>Win Loss Commission</th>
                    <th rowSpan={2}>Company</th>
                    <th rowSpan={2}>SMA PT<br/>MA PT<br/>Associate PT</th>
                    <th rowSpan={2}>IP</th> 
                </tr>
                <tr>
                    <th>Member</th>
                    <th>Associate</th>
                    <th>MA</th>
                    <th>SMA</th>
                </tr>
            </thead>
            <tbody>
                <tr className="light-blue">
                    <th colSpan="13">Sports</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>mpuser0011 <br/>
                        83746374 <br/>
                        Mix Parlay <br/>
                        02/11/2020 <br/>
                        08:23:00 PM
                    </td>
                    <td className="text-align-right pr-3">
                        <span className="emphasis">Mix Parlay</span>
                        <br/><br/>
                        Hougang United FC 0.00 @ 1.46 <br/>
                        <span className="emphasis">Football / Handicap</span> Live! 1:2<br/>
                        Leo Toyoto FC -vs- Hougang United <br/>
                        FC <br/>
                        <span className="orange">AFC Cup @ 02/11/2020<br/>Won</span>
                        <br/><br/>
                        Bali United Pusam -0.05 @ 1.80 <br/>
                        <span className="emphasis">Football / Handicap</span> Live! 1:2<br/>
                        Bali United Pusam -vs- Thanh Quang Ninh<br/>
                        <span className="orange">AFC Cup @ 02/11/2020<br/>Won</span>
                        <br/><br/>
                        Arena FC -0.25 @ 2.08 <br/>
                        <span className="emphasis">Football / Handicap</span> Live! 1:2<br/>
                        Arena FC -vs- Sabah <br/>
                        <span className="orange">East Java Governor Cup (in Indonesia) @ 02/11/2020 <br/>Won</span>
                    </td>
                    <td>5.466<br/><span className="dark-grey">E</span></td>
                    <td>50.00<br/><span className="dark-grey">50.00</span></td>
                    <td>Won</td>
                    <td>15.70<br/>0.00</td>
                    <td><span className="negative">-10.99</span><br/>0.00</td>
                    <td><span className="negative">-1.26</span><br/>0.00</td>
                    <td><span className="negative">-0.31</span><br/>0.00</td>
                    <td><span className="negative">-0.34</span></td>
                    <td className="bg-red">2%<br/>8%<br/>70%</td>
                    <td>127.0.0.1</td>
                </tr>
            </tbody>
        </Table>
        </div>
    </div>
  );
}

export default BetList;
