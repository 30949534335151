import { observable } from "mobx"

const ob = observable({
    expand: false,
    openKey: '1',
    menuCollapsed: false,
    dateString: '',
    subSectionOpenKey: '0',
    showAlert: true,
    // 0 = shareholder, 1 = SMA, 2 = MA, 3 = agent
    hierarchyId: 0,
    section: 'userSection'
});

const toggleExpand = () => {
    ob.expand = !ob.expand;
}

const setActivePage = (key) => { 
    ob.openKey = key;
}
const setSubsectionActivePage = (key) => { 
    ob.subSectionOpenKey = key;
}

const toggleSiderCollapse = () => {
    ob.menuCollapsed = !ob.menuCollapsed;
}

const getDateTime = () => {
    const date = new Date();
    ob.dateString = date.toLocaleString();
    ob.dateString += ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const addHierarchy = () => { 
    if(ob.hierarchyId < 3) {
        ob.hierarchyId++; 
    } else { 
        ob.hierarchyId = 0;
    }
    returnHierarchySectionName(ob.hierarchyId);
}

const returnHierarchySectionName = (hierarchyId) => {
    switch (hierarchyId) { 
        case 0: 
            ob.section = 'userSection'
        break;
        case 1: 
            ob.section = 'userSection smaSection';
        break;
        case 2: 
            ob.section = 'userSection maSection';
        break;
        case 3: 
            ob.section = 'userSection agtSection';
        break;
        default: 
        break;
    }
}

const DashboardStore = { 
    ob,
    toggleExpand,
    setActivePage,
    setSubsectionActivePage,
    toggleSiderCollapse,
    getDateTime,
    addHierarchy,
    returnHierarchySectionName
}

export default DashboardStore; 