import React from 'react';
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import DashboardStore from '../Stores/DashboardStore';
import userlogo from '../Assets/images/userlogo.png';
import riskyplayersicon from '../Assets/images/riskyplayersicon.png';
import mailIcon from '../Assets/images/mailIcon.png';
import notificationsIcon from '../Assets/images/notificationsIcon.png';
import arrowDownIcon from '../Assets/images/arrowdown.png';
import arrowUpIcon from '../Assets/images/arrowup.png';
import { Layout, Menu, Input, Icon, Badge } from 'antd';
const { Sider } = Layout;
const { SubMenu } = Menu;

const NavigationBar = observer((props) => {
    return (
        <Sider
            collapsedWidth={0}
            trigger={null} 
            collapsible 
            collapsed={DashboardStore.ob.menuCollapsed}
            width={300}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            <div className={DashboardStore.ob.section}>
                <div className="userInfo">
                    <img className="userLogo" onClick={() => {DashboardStore.addHierarchy()}} src={userlogo} alt="aaa"/>
                    <div className="userParticulars">
                        <div className="userName">aaa</div>
                        <div className="loginName">aaa2929</div>
                        <div className="userCredit">THB 10,000</div>
                    </div>
                </div>
                <div className="icons">
                    <Badge count={5} className="riskyBadge">
                        <Icon className="riskyPlayersIcon" type="team" />
                    </Badge>
                    <Icon className="mailIcon" type="mail" />
                    <Badge count={100} className="notificationsBadge">
                        <Icon className="notificationsIcon" type="notification" />
                    </Badge>
                </div>
                <div className={(DashboardStore.ob.expand) ? "expandedSection" : "expandedSection hidden"}>
                    <Menu theme="dark" mode="inline">
                        <Menu.Item key="24" disabled={true} className="header-menu-item">
                            <span className="nav-text"><Icon type="tool" />Account Settings</span>
                        </Menu.Item>
                        <Menu.Item key="25">
                            <span className="nav-text">Balance</span>
                        </Menu.Item>
                        <Menu.Item key="26">
                            <span className="nav-text">Activity Log</span>
                        </Menu.Item>
                        <Menu.Item key="27">
                            <Link to='/alias'><span className="nav-text">Alias Management</span></Link>
                        </Menu.Item>
                        <SubMenu
                            className="subSection"
                            key="28"
                            title={
                                <span className="nav-text">Language</span>
                            }
                        >
                            <Menu.Item key="31">English</Menu.Item>
                            <Menu.Item key="32">Thailand</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="29">
                            <span className="nav-text">Password</span>
                        </Menu.Item>
                        <Menu.Item key="30">
                            <span className="nav-text">Sign Out</span>
                        </Menu.Item>
                    </Menu>
                </div>
                {(!DashboardStore.ob.expand) ? 
                (
                    <div>
                        <div className="expandIconBg"></div>
                        <Icon className="expandIcon" type="down" onClick={() => { DashboardStore.toggleExpand() }} />
                    </div>
                ) : (
                    <Icon className="collapseIcon" type="up" onClick={() => { DashboardStore.toggleExpand() }} />
                )}
                
            </div>
            {/* selectedKeys={DashboardStore.ob.openKey} */}
            <Input className="searchUsername" placeholder="Search Username" prefix={<Icon type="search" />} />
            <Menu theme="dark" mode="inline"  >
                <Menu.Item key="1">
                    <Link to='/'><span className="number">1</span><span className="nav-text">Dashboard</span></Link>
                </Menu.Item>
                <Menu.Item key="2" disabled={true} className="header-menu-item">
                    <span className="number">2</span><Icon type="project" /><span className="nav-text">Reports</span>
                </Menu.Item>
                <Menu.Item key="3">
                    <Link to='/shareholderreport'><span className="number">2.1</span><span className="nav-text">
                        {(DashboardStore.ob.hierarchyId !== 0) ? 'Rebate Report' : 'Shareholders Report'}
                    </span></Link>
                </Menu.Item>
                <Menu.Item key="4">
                    <Link to='/winloss'><span className="number">2.2</span><span className="nav-text">Win Loss</span></Link>
                </Menu.Item>
                <Menu.Item key="5">
                    <Link to='/matchwinloss'><span className="number">2.3</span><span className="nav-text">Match Win Loss</span></Link>
                </Menu.Item>
                <Menu.Item key="6">
                    <Link to='/matchresults'><span className="number">2.4</span><span className="nav-text">Match Results</span></Link>
                </Menu.Item>
                <Menu.Item key="7">
                    <Link to='/mystatement'><span className="number">2.5</span><span className="nav-text">My Statement</span></Link>
                </Menu.Item>
                <Menu.Item key="8">
                    <Link to='/memberstatement'><span className="number">2.6</span><span className="nav-text">Member Statement</span></Link>
                </Menu.Item>
                <Menu.Item key="9" disabled={true} className="header-menu-item">
                    <span className="number">3</span><Icon type="user" /><span className="nav-text">Member Management</span>
                </Menu.Item>
                <Menu.Item key="10">
                    <Link to='/createaccount'><span className="number">3.1</span><span className="nav-text">Create Account</span></Link>
                </Menu.Item>
                <Menu.Item key="11">
                    <Link to='/accountlist'><span className="number">3.2</span><span className="nav-text">Account List</span></Link>
                </Menu.Item>
                <Menu.Item key="12" disabled={true} className="header-menu-item">
                    <span className="number">4</span><Icon type="rise" /><span className="nav-text">Bet Settings</span>
                </Menu.Item>
                <Menu.Item key="13">
                    <Link to='/bettinglimit'><span className="number">4.1</span><span className="nav-text">Betting Limit</span></Link>
                </Menu.Item>
                <Menu.Item key="14">
                    <Link to='/commission'><span className="number">4.2</span><span className="nav-text">Commission</span></Link>
                </Menu.Item>
                <Menu.Item key="15">
                    <Link to='/credit'><span className="number">4.3</span><span className="nav-text">Credit</span></Link>
                </Menu.Item>
                <Menu.Item key="16">
                    <Link to='/pt'><span className="number">4.4</span><span className="nav-text">PT</span></Link>
                </Menu.Item>
                <Menu.Item key="17">
                    <Link to='/creditbalance'><span className="number">4.5</span><span className="nav-text">Credit & Balance</span></Link>
                </Menu.Item>
                <Menu.Item key="19" disabled={true} className="header-menu-item">
                    <span className="number">5</span><Icon type="dollar" /><span className="nav-text">Transfer</span>
                </Menu.Item>
                <Menu.Item key="20">
                    <Link to='/transfer'><span className="number">5.1</span><span className="nav-text">Transfer</span></Link>
                </Menu.Item>
            </Menu>
        </Sider>
    )
});

export default NavigationBar;