import React from 'react';
import { observer } from "mobx-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';
import CreditStore from '../Stores/CreditStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { Checkbox, Col, Row, Button, Input, Select } from 'antd';
import Modal from 'react-bootstrap/Modal';

const { Option } = Select;

const Credit = observer((props) => {
  DashboardStore.setActivePage('15');
  DashboardStore.setSubsectionActivePage('0');

  const tableContent = () => {
    let content = [];
  
    for(let i=1; i<=10; i++) {
      if(i<10) {
        content.push(
          <tr key={'row'+i}>
            <td key={i}>{i}</td>
            <td><Checkbox key={'checkbox'+i} checked={CreditStore.ob.checkboxChecked[i]} onChange={() => {checkBoxOnChange(i)}} /></td>
            <td key={'mpuser00' + i}>{'mpuser00' + i}</td>
            <td key={'firstname'+i}>{'tes'}</td>
            <td key={'lastname'+i}>{'test'}</td>
            <td key={'credit'+i} className="text-align-right">{'10,000'}</td>
            <td key={'status'+i}>Active</td>
            <td key={'logintime'+i}>{'0'+i+'/06/2020 0'+i+':47:33 AM'}</td>
          </tr>
        )
      }
    }
  
    return content;
  }

  const checkBoxOnChange = (key) => {
    CreditStore.checkCheckboxItem(key);
  }
  const handleModalOK = () => {
    CreditStore.ob.openModal = false;
    console.log('submit')
  }
  const handleModalCancel = () => {
    CreditStore.ob.openModal = false;
    console.log('cancel')
  }
  const handleBulkEditClick = () => { 
    CreditStore.ob.openModal = true;
    console.log('open')
  }

  return (
    <div className="App creditPage">
      <h2>Credit</h2>
      <Row className="flex-grow-one">
        <Col span={24}>
          <Table className="table" bordered striped >
            <thead>
              <tr className="filter-bar">
                <td colSpan={14}>
                  <Row>
                    <Col span={4} offset={20} className="left-border">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          Active
                        </Dropdown.Toggle>
                      </Dropdown>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <th>#</th>
                <th><Checkbox /></th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Credit</th>
                <th>Status</th>
                <th>Last Login Time</th>
              </tr>
            </thead>
            <tbody>
              {tableContent()}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={(CreditStore.ob.drawer) ? "creditDrawer": "creditDrawer hidden"}>
            <div className="content">
              <Checkbox /> View Selected
              <Button type="primary" onClick={handleBulkEditClick}>Bulk Edit</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal className="creditModal" show={CreditStore.ob.openModal} onHide={handleModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col span={8} className="headerCol">Credit</Col>
            <Col span={16} className="contentCol"><Input /></Col>
          </Row>
          <Row>
            <Col span={8} className="headerCol">Status</Col>
            <Col span={16} className="contentCol">
              <Select defaultValue="active" style={{ 'width': '100%' }}>
                <Option value="active">Active</Option>
                <Option value="closed">Closed</Option>
              </Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="secondary" onClick={handleModalCancel}>
            Close
          </Button>
          <Button type="primary" onClick={handleModalOK}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Credit;
