import React from 'react';
import { Pie } from 'react-chartjs-2';

import { Icon } from 'antd';

const data = {
	labels: [
		'12 Parlays',
		'15 Parlays',
		'17 Parlays',
		'Others'
	],
	datasets: [{
		data: [38, 22, 26, 15],
		backgroundColor: [
		'#5C63A2',
		'#74a77c',
		'#F4AB33',
		'#94c9d0'
		],
		hoverBackgroundColor: [
		'#474d80',
		'#3d8349',
		'#e09d2e',
		'#61B0BA'
		]
	}]
};

const options = {
	responsive: true,
	tooltips: {
		enabled: true,
		mode: 'single',
		callbacks: {
			label: function(tooltipItems, data) { 
				let multistringText;
				switch(tooltipItems.index) {
					case 0: 
						multistringText = [];
						multistringText.push('Turn over: 3,432.00');
						multistringText.push('Winning: 214,232.00');
						multistringText.push('Margin: 25.27%');
					break;
					case 1: 
						multistringText = [];
						multistringText.push('Turn over: 2,234.00');
						multistringText.push('Winning: 232,456.23');
						multistringText.push('Margin: 12.45%');
					break;
					case 2: 
						multistringText = [];
						multistringText.push('Turn over: 1,968.00');
						multistringText.push('Winning: 123,087.23');
						multistringText.push('Margin: 54.23%');
					break;
					case 3: 
						multistringText = [];
						multistringText.push('Turn over: 968.00');
						multistringText.push('Winning: 1,231,087.23');
						multistringText.push('Margin: 34.67%');
					break;
					default: 
					break
				}
				return multistringText;
			}
		}
	}
}

const DashboardTopParlay = () => {
    return (
        <div className="dashboard dashboardMargin dashboardSection">
            <h3><Icon type="pie-chart" />Top 3 Parlays</h3>
            <Pie 
                data={data} 
				height = {204}
				options={options}
            />
        </div>
    );
}

export default DashboardTopParlay;
