import React from 'react';
import { observer } from "mobx-react";
import DashboardStore from '../Stores/DashboardStore';
import RecaptchaImage from '../Assets/images/recaptcha.png';
import { Row, Col, Form, Input, Select, Button } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const CreateAccount = Form.create()(observer((props) => { 
    DashboardStore.setActivePage('10');

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    return (
        <Form {...layout}>
            <div className="App createAccount">
                <h2>Create Account</h2>
                <div className="copySettings">
                    <Row className="header" gutter={12}>
                        <Col>
                            <div>Copy From Existing Account</div>
                        </Col>
                    </Row>
                    <Row className="content" gutter={12}>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='copysettings'
                                    label='Account ID'
                                >
                                    <Select 
                                        showSearch
                                        placeholder={'Search username'}
                                        style={{ 'width': '100%' }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        optionFilterProp="children"
                                    >
                                        <Option value="mpuser0025">mpuser0025</Option>
                                        <Option value="mpuser0045">mpuser0045</Option>
                                        <Option value="mpuser0055">mpuser0055</Option>
                                        <Option value="mpuser0066">mpuser0066</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <Button type="primary">Copy Settings</Button>
                            </Col>
                        </Col>
                    </Row>
                </div>
                <div className="userSettings">
                    <Row className="header" gutter={12}>
                        <Col>
                            <div>User Information</div>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='accountid'
                                        label='Account ID'
                                    >
                                        <Input addonBefore="mpuser" placeholder={'Enter account ID'} />
                                    </FormItem>
                                </Col>
                             </Row>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='password'
                                        label='Password'
                                    >
                                        <Input placeholder="Enter password" />
                                    </FormItem>
                                </Col>
                             </Row>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='firstname'
                                        label='First Name'
                                    >
                                        <Input placeholder="Enter first name" />
                                    </FormItem>
                                </Col>
                             </Row>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='lastname'
                                        label='Last Name'
                                    >
                                        <Input placeholder="Enter last name" />
                                    </FormItem>
                                </Col>
                             </Row>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='phone'
                                        label='Phone'
                                    >
                                        <Input placeholder="Enter phone number" />
                                    </FormItem>
                                </Col>
                             </Row>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='mobile'
                                        label='Mobile'
                                    >
                                        <Input placeholder="Enter mobile number" />
                                    </FormItem>
                                </Col>
                             </Row>
                        </Col>
                    </Row>
                    <Row className="content" gutter={12}>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16} className="recaptchaCol">
                                <FormItem
                                    name='validation'
                                    label='Validation'
                                >
                                    <img src={RecaptchaImage} alt="Recaptcha"/>
                                </FormItem>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="header innerHeader" gutter={12}>
                        <Col>
                            <div>Max Credit / Min Max</div>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='credit'
                                        label='Credit'
                                    >
                                        <Input defaultValue="0" />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <div className="suptext">Max = 190,000</div>
                                </Col>
                             </Row>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='minbet'
                                        label='Min Bet'
                                    >
                                        <Input defaultValue="2" />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <div className="suptext">Min = 2</div>
                                </Col>
                             </Row>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='maxbet'
                                        label='Max Bet'
                                    >
                                        <Input defaultValue="500" />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <div className="suptext">Max = 7,999</div>
                                </Col>
                             </Row>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <FormItem
                                        name='maxpermatch'
                                        label='Max Per Match'
                                    >
                                        <Input defaultValue="50,000" />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <div className="suptext">Max = 100,000</div>
                                </Col>
                             </Row>
                        </Col>
                    </Row>
                    <Row className="header innerHeader" gutter={12}>
                        <Col>
                            <div>Commission</div>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='pcomm1'
                                    label='P Comm 1'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='pcomm2'
                                    label='P Comm 2'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='pcomm3'
                                    label='P Comm 3'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="header innerHeader" gutter={12}>
                        <Col>
                            <div>Position Taking</div>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='pt1'
                                    label='PT1'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='pt2'
                                    label='PT2'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="border-right contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='ahlive'
                                    label='AH Live'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='oulive'
                                    label='OU Live'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col xs={24} xl={12} className="contentPadding">
                            <Col span={16}>
                                <FormItem
                                    name='ptor'
                                    label='PT OR'
                                >
                                    <Select defaultValue="0" style={{ 'width': '100px' }}>
                                        <Option value="0">0.00</Option>
                                        <Option value="1">1.00</Option>
                                        <Option value="2">2.00</Option>
                                        <Option value="3">3.00</Option>
                                    </Select>
                                    <span className="suptext">%</span>
                                </FormItem>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="content innerSection" gutter={12}>
                        <Col>
                            <Button type="primary" className="createBtn">Create Account</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
    )
}));

export default CreateAccount;