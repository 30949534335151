import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Input } from 'antd';

const { Search } = Input;

function getCommaSeparatedTwoDecimalsNumber(number) {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}
const tableContent = () => {
  let content = [];

  for(let i=1; i<=7; i++) {
    if(i===1) {
        content.push(
            <tr className="light-blue" key={'title-row'+i}>
                <th colSpan="17">Mix Parlay</th>
            </tr>
        )
      }
      if(i===3) {
        content.push(
            <tr className="light-blue" key={'title-row'+i}>
                <th colSpan="17">Mix Parlay</th>
            </tr>
        )
      }
      if(i===4) {
        content.push(
            <tr className="light-blue" key={'title-row'+i}>
                <th colSpan="17">Mix Parlay</th>
            </tr>
        )
      }
      if(i===6) {
        content.push(
            <tr className="light-blue" key={'title-row'+i}>
                <th colSpan="17">Mix Parlay</th>
            </tr>
        )
      }
    content.push(
        <tr key={'row'+i}>
            <td>{(i===1 || i===4|| i===6 || i===8) ? 'Live! 1X2' : 'Live! Over/Under'}</td>
            <td>{(i%2===0) ? '88.00' : '144.00'}</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>{(i%3===0) ? '1.64' : '0.00'}</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>{(i%3===0) ? '1.64' : '0.00'}</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>{(i%3===0) ? '1.64' : '0.00'}</td>
            <td>0.00</td>
            <td>0.00</td>
            <td className="negative">-1.64</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>0.00</td>
        </tr>
    )
  }

  return content;
}
function MatchWinloss() {
    DashboardStore.setActivePage('5');
    DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
        <Row>
            <Col md={{ span: 8 }}>
                <h2>Match Win Loss</h2>
            </Col>
            <Col md={{ span: 4 }} className="text-align-right">
                <Search
                    className="title-searchbar"
                    placeholder="Search"
                    onSearch={value => console.log(value)}
                    style={{ width: 200 }}
                />
            </Col>
        </Row>
      <div style={{"overflowX":"auto", "overflowY": "auto"}}>
        <Table className="table" bordered striped >
            <thead>
                <tr className="filter-bar">
                    <td colSpan={17}>
                        <Row className="filter-bar-row justify-flex-start">
                            <Col md={{ span: 2 }} className="right-border">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Today
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                            <Col md={{ span: 2 }} className="pr-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                            <Col className="px-1 align-self-center daterange-to">to</Col>
                            <Col md={{ span: 2 }} className="pl-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <th rowSpan={2}>Bet Type</th>
                    <th rowSpan={2}>Turnover</th>
                    <th rowSpan={2}>Gross Comm</th>
                    <th colSpan={3}>Member</th>
                    <th colSpan={3}>Associate</th>
                    <th colSpan={3}>MA</th>
                    <th colSpan={3}>SMA</th>
                    <th colSpan={2}>Company</th>
                </tr>
                <tr>
                    <th>Win</th>
                    <th>Comm</th>
                    <th>Total</th>
                    <th>Win</th>
                    <th>Comm</th>
                    <th>Total</th>
                    <th>Win</th>
                    <th>Comm</th>
                    <th>Total</th>
                    <th>Win</th>
                    <th>Comm</th>
                    <th>Total</th>
                    <th>Comm</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {tableContent()}
            </tbody>
        </Table>
        </div>
    </div>
  );
}

export default MatchWinloss;
