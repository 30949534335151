import React from 'react';
import { observer } from "mobx-react";
import { Layout, Icon } from 'antd';
import DashboardStore from '../Stores/DashboardStore';
const { Header } = Layout;

const HeaderSection = observer((props) => {
    setInterval( () => {
        DashboardStore.getDateTime();
    },1000)

    return (
        <Header className="site-layout-background" style={{ padding: 0 }}>
            {
                (!DashboardStore.ob.menuCollapsed) ? 
                    <Icon 
                        onClick={() => { DashboardStore.toggleSiderCollapse() }} 
                        className='trigger' 
                        type="menu-fold" /> : 
                    <Icon 
                        onClick={() => { DashboardStore.toggleSiderCollapse() }} 
                        className='trigger' 
                        type="menu-unfold" />
            }
            <div className="rightSection">
                <div className="date">{DashboardStore.ob.dateString}</div>
                <Icon className="icon infoIcon" type="info-circle" theme="filled" />
                <Icon className="icon phoneIcon" type="phone" theme="filled" />
                <Icon className="icon logoutIcon" type="logout" />
            </div>
        </Header>
    )
});

export default HeaderSection;