import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Checkbox } from 'antd';

const tableContent = () => {
  let content = [];

  for(let i=1; i<=8; i++) {
    if(i<10) {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser00' + i}</td>
          <td>{'tes'}</td>
          <td>{'test'}</td>
          <td>0 <sup>(85)</sup></td>
          <td>0 <sup>(80)</sup></td>
          <td>70</td>
          <td>0</td>
          <td>Yes</td>
          <td></td>
        </tr>
      )
    }
    else {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser0' + i}</td>
          <td>{''}</td>
          <td>{''}</td>
          <td>0 <sup>(85)</sup></td>
          <td>0 <sup>(80)</sup></td>
          <td>70</td>
          <td>0</td>
          <td>Yes</td>
          <td></td>
        </tr>
      )
    }
  }

  return content;
}
function PT() {
  DashboardStore.setActivePage('16');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>PT</h2>
      <Table className="table vertical-align-middle" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={14}>
              <Row>
                {/* <Col md={{ span: 2 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Football
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
                <Col md={{ span: 3 }}>
                  <Form.Check 
                    type="checkbox"
                    className="filter-checkbox text-align-left"
                    label="Apply changes to all sports type"
                  />
                </Col> */}
                <Col md={{ span: 2, offset: 10 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Active
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}><Checkbox /></th>
            <th rowSpan={2}>Username</th>
            <th rowSpan={2}>First Name</th>
            <th rowSpan={2}>Last Name</th>
            <th rowSpan={2}>Min PT</th>
            <th rowSpan={2}>Forced PT</th>
            <th colSpan={2}>Effective PT</th>
            <th rowSpan={2}>Take Remaining</th>
            <th rowSpan={2}>Status</th>
          </tr>
          <tr>
            <th>MA</th>
            <th>Associate</th>
          </tr>
        </thead>
        <tbody>
          {tableContent()}
        </tbody>
      </Table>
    </div>
  );
}

export default PT;