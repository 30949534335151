import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Breadcrumb, Button } from 'antd';
import { Link } from 'react-router-dom';

function ShareholderReportAgt() {
  DashboardStore.setActivePage('3');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Rebate Report</h2>
      <div className="breadcrumbs">
        <Row>
          <Col md={{ span: 8 }}>
            <Breadcrumb>
              <Breadcrumb.Item href="/shareholderreport">
                <Icon className="icon" type="team" />
                <span>All</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/shareholderreportSMA">
                <Icon className="icon" type="user" />
                <span>A</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/shareholderreportMA">
                <Icon className="icon" type="user" />
                <span>smauser001</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/shareholderreportAgt">
                <Icon className="icon" type="user" />
                <span>mauser001</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col md={{ span: 4 }} className="text-align-right">
              <Button type="primary" icon="file-excel">Export to Excel</Button>
          </Col>
        </Row> 
      </div>
      <Table className="table" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={14}>
              <Row className="filter-bar-row justify-flex-start">
                <Col md={{ span: 2 }} className="right-border">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        Today
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
                <Col md={{ span: 2 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="pr-1">
                        02/21/2020
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
                <Col className="px-1 align-self-center daterange-to">to</Col>
                <Col md={{ span: 2 }} className="pl-1">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        02/21/2020
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <th>Account</th>
            <th>Co.Turnover</th>
            <th>Co.Winloss</th>
            <th>% Turnover share</th>
            <th>Rebate given</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td><Link to='/betlist'><span className="dotted-underline">agtuser001</span></Link></td>
            <td>100</td>
            <td>10</td>
            <td>25%</td>
            <td>5</td>
          </tr>
          <tr>
            <td><Link to='/betlist'><span className="dotted-underline">agtuser002</span></Link></td>
            <td>200</td>
            <td>15</td>
            <td>50%</td>
            <td>10</td>
          </tr>
          <tr>
          <td><Link to='/betlist'><span className="dotted-underline">agtuser003</span></Link></td>
            <td>100</td>
            <td>15</td>
            <td>25%</td>
            <td>5</td>
          </tr>
          <tr>
            <td className="text-align-right bold">Total</td>
            <td className="bold">400</td>
            <td className="bold">40</td>
            <td className="bold">100%</td>
            <td className="bold">20</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ShareholderReportAgt;
