import { observable, autorun } from "mobx"

const ob = observable({
    checkboxChecked: [],
    drawer: false,
    openModal: false
});
const disposer = autorun(
    () => {
        for(let i=1; i<=10; i++) {
            ob.checkboxChecked.push(false);
        }
    }
)
disposer();

const checkCheckboxItem = (key) => {
    ob.checkboxChecked[key] = !ob.checkboxChecked[key];

    let allUnchecked = true;
    for(let i =1; i<= 10; i++) {
        if(ob.checkboxChecked[i]) {
            allUnchecked = false;
        }
    }
    if(allUnchecked) {
        hideDrawer();
    }
    else {
        showDrawer();
    }
}

const uncheckAllCheckboxItem = () => {
    for(let i=1; i<=10; i++) {
        ob.checkboxChecked[i] = false;
    }
}

const showDrawer = () => { 
    ob.drawer = true;
}

const hideDrawer = () => {
    ob.drawer = false;
}

const openModal = () => {
    ob.openModal = !ob.openModal;
}

const CreditStore = {
    ob,
    checkCheckboxItem,
    uncheckAllCheckboxItem,
    showDrawer,
    hideDrawer,
    openModal
};

export default CreditStore;