import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Icon } from 'antd';

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        type: 'bar',
        label: 'Turnover',
        data: [200, 185, 590, 621, 250, 400, 95],
        fill: false,
        backgroundColor: '#8a90c2',
        borderColor: '#8a90c2',
        hoverBackgroundColor: '#5C63A2',
        hoverBorderColor: '#5C63A2'
      }]
};

const DashboardSportsTurnover = () => {
    return (
        <div className="dashboard dashboardSportsTurnover dashboardSection">
            <h3><Icon type="bar-chart" />Sports Turnover</h3>
            <Bar
                data={data}
                fullWidth={true}
                // width={100}
                // height={50}
                // options={{
                //     maintainAspectRatio: false
                // }}
            />
        </div>
    );
}

export default DashboardSportsTurnover;
