import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Table from 'react-bootstrap/Table';
import { Icon, Tabs  } from 'antd';

const { TabPane } = Tabs;

function App() {
  return (
    <div className="dashboard dashboardBalanceInformation">
        <Tabs type="card">
            <TabPane tab={<span><Icon type="wallet" />Balance Information</span>} key="3">
                <Table className="table dashboardBalance" bordered striped >
                    <tbody>
                        <tr>
                            <td className="text-align-right">Username</td>
                            <td className="text-align-left">mpuser</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Login Name</td>
                            <td className="text-align-left">mpuser1</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Cash Balance</td>
                            <td className="text-align-left">0.00</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Yesterday Balance</td>
                            <td className="text-align-left">0.00</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Total Balance</td>
                            <td className="text-align-left">0.00</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Associate Outstanding</td>
                            <td className="text-align-left">3,116.74</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Total Outstanding</td>
                            <td className="text-align-left">3,116.74</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Associate Credit</td>
                            <td className="text-align-left">100,000.00</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Member Credit</td>
                            <td className="text-align-left">100,000.00</td>
                        </tr>
                        <tr>
                            <td className="text-align-right">Total Member Count</td>
                            <td className="text-align-left">6 ( 6 + <span className="negative">0</span> )</td>
                        </tr>
                    </tbody>
                </Table>
            </TabPane>
        </Tabs>
    </div>
  );
}

export default App;
