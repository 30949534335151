import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { Checkbox } from 'antd';

const tableContent = () => {
  let content = [];

  for(let i=1; i<=12; i++) {
    if(i<10) {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser00' + i}</td>
          <td>{'tes'}</td>
          <td>{'test'}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>{i+'.00'}</td>
          <td>{i+'.00'}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>{i+'.00'}</td>
          <td>{i+'.00'}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>{i+'.00'}</td>
          <td>{i+'.00'}</td>
          <td>Active</td>
          <td>{'0'+i+'/06/2020 0'+i+':47:33 AM'}</td>
        </tr>
      )
    }
    else {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td><Checkbox/></td>
          <td>{'mpuser0' + i}</td>
          <td>{'tes'}</td>
          <td>{'test'}</td>
          <td>{i+'.00'}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>{i+'.00'}</td>
          <td>{i+'.00'}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>{i+'.00'}</td>
          <td>{i+'.00'}</td>
          <td>{i+'.00'}</td>
          <td>0.00</td>
          <td>Active</td>
          <td>02/06/2020 02:47:33 AM</td>
        </tr>
      )
    }
  }

  return content;
}
function Commission() {
  DashboardStore.setActivePage('14');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Commission</h2>
      <div style={{"overflowX":"auto", "overflowY": "auto"}}>
        <Table className="table" bordered striped >
          <thead>
            <tr className="filter-bar">
              <td colSpan={25}>
                <Row>
                  <Col md={{ span: 2, offset: 10 }}>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        Active
                      </Dropdown.Toggle>
                    </Dropdown>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <th>#</th>
              <th><Checkbox /></th>
              <th>Username</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th style={{'minWidth': '95px'}}>3 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>5 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>4 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>6 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>7 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>8 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>9 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>10 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>11 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>12 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>13 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>14 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>15 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>16 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>17 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>18 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>19 Parlays (%)</th>
              <th style={{'minWidth': '95px'}}>20 Parlays (%)</th>
              <th>Status</th>
              <th>Last Login Time</th>
            </tr>
          </thead>
          <tbody>
            {tableContent()}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Commission;
