import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Table from 'react-bootstrap/Table';

const tableContent = () => {
  let content = [];

  for(let i=10; i<20; i++) {
      content.push(
        <tr key={'row'+i}>
          <td>{'mpuser0' + i}</td>
          <td>EURO</td>
          <td>{i}</td>
          <td>{i*20}</td>
          <td>{i*25}</td>
          <td>{i*24}</td>
          <td className="text-align-right">{(i%3===0)? '21.00%' : '2,344.00%'}</td>
          <td className="text-align-right">{(i%3===0)? '34.00' : '23.00'}</td>
          <td className="text-align-right">{(i%3===0)? '454.00' : '45.00'}</td>
          <td className="text-align-right">{'1,000.00'}</td>
          <td className="text-align-right">{(i%3===0)? '2,231.00' : '5,645.00'}</td>
        </tr>
      )
  }

  return content;
}
function DashboardLosingPlayers() {
  return (
    <div className="dashboard">
      <Table className="table" bordered striped >
          <thead>
          <tr>
              <th rowSpan={2}>Username</th>
              <th rowSpan={2}>Currency</th>
              <th colSpan={4}>Bet Count</th>
              <th rowSpan={2}>Win %</th>
              <th rowSpan={2}>Avg. Bet</th>
              <th rowSpan={2}>Lowest Bet</th>
              <th rowSpan={2}>Highest Bet</th>
              <th rowSpan={2}>Win Amount</th>
          </tr>
          <tr>
              <th>Total #</th>
              <th>Win #</th>
              <th>Draw #</th>
              <th>Lose #</th>
          </tr>
          </thead>
          <tbody>
          {tableContent()}
          </tbody>
      </Table>
    </div>
  );
}

export default DashboardLosingPlayers;
