import React from 'react';
import { Line } from 'react-chartjs-2';

import { Icon } from 'antd';

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Winnings',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#799aad',
        borderColor: '#1b4e6b',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#1b4e6b',
        pointBackgroundColor: '#1b4e6b',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#1b4e6b',
        pointHoverBorderColor: '#1b4e6b',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
};

const DashboardSportsTotalWinnings = () => {
    return (
        <div className="dashboard dashboardSportsTotalWinnings dashboardSection">
            <h3><Icon type="area-chart" />Sports Total Winnings</h3>
            <Line data={data} />
        </div>
    );
}

export default DashboardSportsTotalWinnings;
