import React from 'react';
import ShareholderReport from '../Pages/ShareholderReport';
import ShareholderReportSMA from '../Pages/ShareholderReportSMA';
import ShareholderReportMA from '../Pages/ShareholderReportMA';
import ShareholderReportAgt from '../Pages/ShareholderReportAgt';
import BetList from '../Pages/BetList';
import Winloss from '../Pages/WinLoss';
import MatchWinloss from '../Pages/MatchWinLoss';
import MatchResults from '../Pages/MatchResults';
import MyStatement from '../Pages/MyStatement';
import MemberStatement from '../Pages/MemberStatement';
import AccountList from '../Pages/AccountList';
import BettingLimit from '../Pages/BettingLimit';
import Commission from '../Pages/Commission';
import Credit from '../Pages/Credit';
import PT from '../Pages/PT';
import CreditBalance from '../Pages/CreditBalance';
import Transfer from '../Pages/Transfer';
import Dashboard from '../Pages/Dashboard';
import Alias from '../Pages/AliasManagement';
import CreateAccount from '../Pages/CreateAccount';
import { Switch, Route } from 'react-router-dom';

const Main = () => { 
    return (
        <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/shareholderreport' component={ShareholderReport} />
            <Route exact path='/shareholderReportSMA' component={ShareholderReportSMA} />
            <Route exact path='/shareholderreportMA' component={ShareholderReportMA} />
            <Route exact path='/shareholderreportAgt' component={ShareholderReportAgt} />
            <Route exact path='/betlist' component={BetList} />
            <Route exact path='/winloss' component={Winloss} />
            <Route exact path='/matchwinloss' component={MatchWinloss} />
            <Route exact path='/matchresults' component={MatchResults} />
            <Route exact path='/mystatement' component={MyStatement} />
            <Route exact path='/memberstatement' component={MemberStatement} />
            <Route exact path='/createaccount' component={CreateAccount} />
            <Route exact path='/accountlist' component={AccountList} />
            <Route exact path='/bettinglimit' component={BettingLimit} />
            <Route exact path='/commission' component={Commission} />
            <Route exact path='/credit' component={Credit} />
            <Route exact path='/pt' component={PT} />
            <Route exact path='/creditbalance' component={CreditBalance} />
            <Route exact path='/transfer' component={Transfer} />
            <Route exact path='/alias' component={Alias} />
        </Switch>
    )
};

export default Main;