import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Breadcrumb, Button } from 'antd';

function getCommaSeparatedTwoDecimalsNumber(number) {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}
const tableContent = () => {
  let content = [];

  for(let i=1; i<=8; i++) {
    if(i===1) {
        content.push(
            <tr key={'currency'+i} className="light-blue">
                <th colSpan="19">IDR</th>
            </tr>
        )
      }
      if(i===5) {
        content.push(
            <tr key={'currency'+i} className="light-blue">
                <th colSpan="19">THB</th>
            </tr>
        )
      }
    content.push(
    <tr key={'row'+i}>
        <td>{(i > 4) ? i-4: i}</td>
        <td><span className="dotted-underline">{'mpuser00' + i}</span></td>
        <td>{(i < 5) ? 'IDR' : 'THB' }</td>
        <td>{getCommaSeparatedTwoDecimalsNumber(10264.65 + ( i * 32))}</td>
        <td>0.00</td>
        <td className="negative">{getCommaSeparatedTwoDecimalsNumber(-124.23 - ( i * 4))}</td>
        <td>0.00</td>
        <td>{(i%4===0) ? getCommaSeparatedTwoDecimalsNumber(720.76 - ( i * 4)) : '0.00'}</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
    </tr>
    )
  }

  return content;
}
function Winloss() {
    DashboardStore.setActivePage('4');
    DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
        <h2>Win Loss</h2>
        <div className="breadcrumbs">
            <Row>
                <Col md={{ span: 8 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="">
                            <Icon className="icon" type="team" />
                            <span>All</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={{ span: 4 }} className="text-align-right">
                    <Button type="primary" icon="file-excel">Export to Excel</Button>
                </Col>
            </Row>
        </div>
      <div style={{"overflowX":"auto", "overflowY": "auto"}}>
        <Table className="table" bordered striped >
            <thead>
                <tr className="filter-bar">
                    <td colSpan={19}>
                        <Row className="filter-bar-row justify-flex-start">
                            <Col md={{ span: 2 }} className="right-border">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Today
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                            <Col md={{ span: 2 }} className="pr-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                            <Col className="px-1 align-self-center daterange-to">to</Col>
                            <Col md={{ span: 2 }} className="pl-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                            </Col>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th>Currency</th>
                    <th>Turnover</th>
                    <th>Gross Comm</th>
                    <th>Member Win</th>
                    <th>Member Comm</th>
                    <th style={{minWidth: '140px'}}>Member Jackpot Winloss</th>
                    <th>Member Total</th>
                    <th>Associate Win</th> 
                    <th>Associate Comm</th>
                    <th>Associate Total</th>
                    <th>MA Win</th>
                    <th>MA Comm</th>
                    <th>MA Total</th>
                    <th>SMA Win</th>
                    <th>SMA Comm</th>
                    <th>SMA Total</th>
                    <th>Company</th>
                </tr>
            </thead>
            <tbody>
                {tableContent()}
            </tbody>
        </Table>
        </div>
    </div>
  );
}

export default Winloss;
