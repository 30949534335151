import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const tableContent = () => {
  let content = [];

  for(let i=1; i<=16; i++) {
    if(i<2) {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td>{'0'+i+'/06/2020 07:47:33 AM'}</td>
          <td>{'Opening Balance'}</td>
          <td className="text-align-right">{i+'00.00'}</td>
          <td className="text-align-right">0.00</td>
          <td className="negative text-align-right">-100,234.27</td>
        </tr>
      )
    }
    else {
      content.push(
        <tr key={'row'+i}>
          <td>{i}</td>
          <td>{'03/06/2020 02:47:33 AM'}</td>
          <td>{'Betting'}</td>
          <td className="text-align-right">{i+'00.00'}</td>
          <td className="text-align-right">0.00</td>
          <td className="text-align-right">0.00</td>
        </tr>
      )
    }
  }

  return content;
}

function MemberStatement() {
  DashboardStore.setActivePage('8');
  DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App">
      <h2>Member Statement</h2>
      <Table className="table" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={14}>
                <Row className="filter-bar-row">
                    <Col md={{span:6}} className="flex justify-flex-start">
                        <Col md={{ span: 4 }} className="right-border pl-0">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Today
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        <Col md={{ span: 4 }} className="pr-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        <Col className="px-1 align-self-center daterange-to">to</Col>
                        <Col md={{ span: 4 }} className="pl-1">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    02/21/2020
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                    </Col>
                    <Col md={{span:4, offset: 2}} className="flex justify-flex-end pr-0 pl-1 left-border">
                        <Col md={{ span: 6}}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                            mpuser001
                            </Dropdown.Toggle>
                        </Dropdown>
                        </Col>
                        <Col md={{ span: 6 }} className="pl-0">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                            Statement
                            </Dropdown.Toggle>
                        </Dropdown>
                        </Col>
                    </Col>
                </Row>
            </td>
          </tr>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Type</th>
            <th>Betting</th>
            <th>Commission</th>
            <th>Cash Balance</th>
          </tr>
        </thead>
        <tbody>
            {tableContent()}
            <tr className="">
                <td colSpan="5" className="bold text-align-right">Total</td>
                <td className="bold negative text-align-right">-100,234.27</td>
            </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default MemberStatement;
