import React from 'react';
import { observer } from "mobx-react"
import { Link } from 'react-router-dom';

import DashboardStore from '../Stores/DashboardStore';
import DashboardWinningPlayers from '../Components/DashboardWinningPlayers';
import DashboardLosingPlayers from '../Components/DashboardLosingPlayers';
import DashboardBalanceInformation from '../Components/DashboardBalanceInfomation';
import DashboardSportsTurnover from '../Components/DashboardSportsTurnover';
import DashboardTotalWinnings from '../Components/DashboardSportsTotalWinnings';
import DashboardTopParlay from '../Components/DashboardTopParlay';
import { Row, Col, Icon, Tabs, DatePicker } from 'antd';
import moment from 'moment';

const { MonthPicker, RangePicker } = DatePicker;
const { TabPane } = Tabs;
const dateFormat = 'DD MMM, YYYY';

const dateFormatList = ['DD/MMM/YYYY', 'DD/MMM/YY'];

const Dashboard = observer((props) => {
    DashboardStore.setActivePage('1');
    DashboardStore.setSubsectionActivePage('0');

  return (
    <div className="App dashboard">
      <h2>Hello aaa!</h2>
      <div className={(DashboardStore.ob.showAlert) ? "customAlert" : 'customAlert hidden'}>
        Your member <span className="bold">aaa111</span> and <span className="bold">bbb111</span> have won the jackpot. Click <Link to="/winloss">here</Link> for full report.
        <Icon onClick={() => DashboardStore.ob.showAlert = false} type="close" />
      </div>
      <RangePicker
        className="datepicker"
        defaultValue={[moment(), moment()]}
        format={dateFormat}
      />
      <Row gutter={24}>
        <Col span={16}>
          <Row>
            <Tabs type="card">
              <TabPane tab={<span><Icon className="icon" type="team" />Top 10 Winning Players</span>} key="1">
                <DashboardWinningPlayers/>
              </TabPane>
              <TabPane tab={<span><Icon className="icon" type="team" />Top 10 Losing Players</span>} key="2">
                <DashboardLosingPlayers />
              </TabPane>
            </Tabs>
          </Row>
          <Row gutter={24}>
            <Col span={12}><DashboardSportsTurnover/></Col>
            <Col span={12}><DashboardTotalWinnings/></Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <DashboardBalanceInformation/>
            <DashboardTopParlay />
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default Dashboard;
