import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardStore from '../Stores/DashboardStore';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Checkbox } from 'antd';
const tableContent = () => {
  let content = [];

  for(let i=1; i<=20; i++) {
    if(i<10) {
      content.push(
        <tr key={"row" + i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser00' + i}</td>
          <td></td>
          <td><span className="link">{'Open'}</span></td>
          <td><span className="link">{'Unlock'}</span></td>
          <td><span className="link">{(i%4===0) ? 'Test ts' : ''}</span></td>
          <td><Checkbox checked={(i%3===0) ? true : false} /></td>
          <td><Checkbox checked /></td>
          <td><Checkbox checked={(i%5===0) ? true : false} /></td>
          <td><Checkbox checked={(i%5===0) ? true : false} /></td>
          <td>{'0'+i+'/06/2020 0'+i+':47:33 AM'}</td>
          <td>127.0.0.1</td>
        </tr>
      )
    }
    else {
      content.push(
        <tr key={"row" + i}>
          <td>{i}</td>
          <td><Checkbox /></td>
          <td>{'mpuser0' + i}</td>
          <td></td>
          <td><span className="link">{'Open'}</span></td>
          <td><span className="link">{'Unlock'}</span></td>
          <td><span className="link">{(i%3===0) ? 'Test ts' : ''}</span></td>
          <td><Checkbox checked={(i%5===0) ? true : false} /></td>
          <td><Checkbox checked type="checkbox"/></td>
          <td><Checkbox checked={(i%6===0) ? true : false} /></td>
          <td><Checkbox checked={(i%6===0) ? true : false} /></td>
          <td>{'15/06/2020 0'+i+':47:33 AM'}</td>
          <td>127.0.0.1</td>
        </tr>
      )
    }
  }

  return content;
}
function Alias() {
  DashboardStore.setSubsectionActivePage('27');
  DashboardStore.setActivePage('0');
  
  return (
    <div className="App">
      <h2>Alias Management</h2>
      <Table className="table" bordered striped >
        <thead>
          <tr className="filter-bar">
            <td colSpan={13}>
              <Row>
                <Col md={{ span: 2, offset: 10 }}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        Active
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <th>#</th>
            <th><Checkbox /></th>
            <th>Username</th>
            <th>Login Name</th>
            <th>Status</th>
            <th>Lock</th>
            <th>Name</th>
            <th>Member</th>
            <th>Report</th>
            <th>Transfer</th>
            <th>Tag</th>
            <th>Last Login Time</th>
            <th>IP</th>
          </tr>
        </thead>
        <tbody>
          {tableContent()}
        </tbody>
      </Table>
    </div>
  );
}

export default Alias;
